var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "row" },
    [
      _c(
        "div",
        { staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" },
        [
          _c(
            "c-table",
            {
              ref: "grid",
              attrs: {
                title: "방지시설별 유지보수 이력",
                tableId: "grid",
                columnSetting: false,
                isFullScreen: false,
                columns: _vm.grid.columns,
                merge: _vm.grid.merge,
                filtering: false,
                data: _vm.grid.data,
              },
              on: { linkClick: _vm.linkClick },
            },
            [
              _c(
                "template",
                { slot: "table-button" },
                [
                  _c(
                    "q-btn-group",
                    { attrs: { outline: "" } },
                    [
                      _vm.editable
                        ? _c("c-btn", {
                            attrs: { label: "LBLREG", icon: "add" },
                            on: { btnClicked: _vm.detailPopup },
                          })
                        : _vm._e(),
                      _c("c-btn", {
                        attrs: { label: "LBLSEARCH", icon: "search" },
                        on: { btnClicked: _vm.getList },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            2
          ),
        ],
        1
      ),
      _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }