<template>
  <div class="row">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
      <c-table
        ref="grid"
        title="방지시설별 유지보수 이력"
        tableId="grid"
        :columnSetting="false"
        :isFullScreen="false"
        :columns="grid.columns"
        :merge="grid.merge"
        :filtering="false"
        :data="grid.data"
        @linkClick="linkClick"
      >
        <template slot="table-button">
          <q-btn-group outline >
          <c-btn v-if="editable" label="LBLREG" icon="add" @btnClicked="detailPopup" />
          <c-btn label="LBLSEARCH" icon="search" @btnClicked="getList" />
          </q-btn-group>
        </template>
      </c-table>
    </div>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>
<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'gov-schedule-info',
  props: {
    param: {
      type: Object,
      default: () => ({
        envAirMstPreventiveId: '',
      }),
    },
  },
  data() {
    return {
      title: '',
      grid: {
        merge: [
          { index: 0, colName: 'envAirDailyResultPreventiveHistoryId' },
          { index: 1, colName: 'envAirDailyResultPreventiveHistoryId' },
          { index: 2, colName: 'envAirDailyResultPreventiveHistoryId' },
          { index: 3, colName: 'envAirDailyResultPreventiveHistoryId' },
          { index: 4, colName: 'envAirDailyResultPreventiveHistoryId' },
          { index: 7, colName: 'envAirDailyResultPreventiveHistoryId' },
        ],
        columns: [
          {
            name: 'plantName',
            field: 'plantName',
            label: '사업장',
            align: 'center',
            style: 'width:100px',
            sortable: true,
          },
          {
            name: 'preventiveTypeName',
            field: 'preventiveTypeName',
            label: '유지보수 구분',
            align: 'center',
            style: 'width:120px',
            sortable: true,
          },
          {
            name: 'envAirMstPreventiveName',
            field: 'envAirMstPreventiveName',
            label: '방지시설',
            align: 'center',
            style: 'width:200px',
            type: 'link',
            sortable: true,
          },
          {
            name: 'startDt',
            field: 'startDt',
            label: '유지보수 일자',
            align: 'center',
            style: 'width:140px',
            sortable: true,
          },
          {
            name: 'userName',
            field: 'userName',
            label: '작업관리자',
            align: 'center',
            style: 'width:140px',
            sortable: true,
          },
          {
            name: 'partName',
            field: 'partName',
            label: '교체 소모품',
            align: 'center',
            style: 'width:200px',
            sortable: true,
          },
          {
            name: 'partCnt',
            field: 'partCnt',
            label: '교체 소모품수량',
            align: 'right',
            type: 'cost',
            style: 'width:130px',
            sortable: true,
          },
          {
            name: 'remark',
            field: 'remark',
            label: '유지보수 내용',
            align: 'left',
            sortable: true,
          },
        ],
        data: [],
      },
      nowplantCd: '',
      listUrl: '',
      editable: true,
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        isFull: false,
        width: '80%',
        top: '',
        param: {},
        closeCallback: null,
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      this.listUrl = selectConfig.env.air.daily.preventive.history.list.url;

      this.getList();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = {envAirMstPreventiveId: this.param.envAirMstPreventiveId};
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    linkClick(row) {
      this.detailPopup(row);
    },
    detailPopup(result) {
      this.popupOptions.target = () => import(`${"./airOutletPartDetail.vue"}`);
      this.popupOptions.title = '방지시설 보수/교체 상세';
      this.popupOptions.param = {
        envAirDailyResultPreventiveHistoryId: result ? result.envAirDailyResultPreventiveHistoryId : '',
        envAirMstPreventiveId: this.param.envAirMstPreventiveId,
      };
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.getList();
    },
  }
};
</script>